import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import React from "react";

const PhysioCard = () => {
  const headingStyle = {
    color: (theme) => theme.palette.primary.main,
  };

  const paragraphStyle = {
    marginBottom: "1em",
    "&:last-of-type": {
      marginBottom: 0,
    },
  };

  return (
    <Card
      sx={{
        maxWidth: "500px",
        backgroundColor: (theme) => theme.palette.transparent.white1,
      }}
      square
    >
      <CardHeader
        title={
          <Typography variant="h5" align="center">
            Physiotherapy
          </Typography>
        }
      />
      <CardContent>
        <Typography variant="h5" sx={headingStyle} gutterBottom>
          What is Physio and what do Physio’s do?
        </Typography>
        <Typography
          variant="body1"
          align="justify"
          sx={paragraphStyle}
          gutterBottom
        >
          Physiotherapy is an evidence-based healthcare profession, which is
          continually challenged by the research. Physiotherapists are outcome
          focussed professionals, who can accurately identify a problem through
          a comprehensive assessment and then provide advice on strength and
          rehab programs to facilitate a goal directed outcomes.
        </Typography>
        <Typography variant="h5" sx={headingStyle} gutterBottom>
          What sets Physios apart from other allied health professions?
        </Typography>
        <Typography
          variant="body1"
          align="justify"
          sx={paragraphStyle}
          gutterBottom
        >
          Physiotherapists are University-educated professionals, who have a
          large amount of exposure in the private and hospital sectors during
          our training. Through this exposure we see a large variety of
          conditions and are required to work alongside other medical
          professionals. We have a strong focus on Biomechanics and are trained
          to objectively assess your issue which will allow us to identify the
          problem. We are committed to providing treatment programs to ensure
          function is restored and a return to performance can be achieved
          through end to end management.
        </Typography>

        <Typography variant="h5" sx={headingStyle} gutterBottom>
          Is a GP referral needed?
        </Typography>
        <Typography
          variant="body1"
          align="justify"
          sx={paragraphStyle}
          gutterBottom
        >
          No, you are not required to get a GP referral to see a Physio.
          However, for a Chronic Disease Management Program, you will need a GP
          referral for this service. Speak to your GP about your eligibility for
          this program.
        </Typography>

        <Typography variant="h5" sx={headingStyle} gutterBottom>
          Can I claim Physiotherapy as an extra on my health insurance policy?{" "}
        </Typography>
        <Typography
          variant="body1"
          align="justify"
          gutterBottom
          sx={paragraphStyle}
        >
          Physiotherapy is a standard optional extra on your private health
          insurance. Please check with your own provider and level cover as to
          your rebate options. This is dictated by your private health insurers,
          not by the clinic/clinician.
        </Typography>

        <Typography variant="h5" sx={headingStyle} gutterBottom>
          Do you provide a compensable claim service (Workcover/TAC)?{" "}
        </Typography>
        <Typography
          variant="body1"
          align="justify"
          sx={paragraphStyle}
          gutterBottom
        >
          Yes, we do. Please give our front desk team a call to chat through
          your situation and needs.
        </Typography>
      </CardContent>
    </Card>
  );
};

export default PhysioCard;
