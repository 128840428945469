import { Grid } from "@mui/material";
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import React from "react";
import BackgroundSection from "../components/BackgroundSection";
import BookNowButton from "../components/BookNowButton";
import ExerciseCard from "../components/ExerciseCard";
import Footer from "../components/Footer";
import PageSection from "../components/PageSection";
import PhysioCard from "../components/PhysioCard";
import ServiceTable from "../components/ServiceTable";
import TopBanner from "../components/TopBanner";
import TopBar from "../components/TopBar";
import { greys } from "../theme";

const ServicesPage = () => {
  const { mainBackground } = useStaticQuery(graphql`
    query {
      mainBackground: file(relativePath: { eq: "road-running.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1024
            placeholder: BLURRED
            formats: [AUTO, JPG]
          )
        }
      }
    }
  `);

  const mainBackgroundImage = getImage(mainBackground);

  return (
    <>
      <TopBar />
      <BackgroundSection
        backgroundPosition="center center"
        backgroundImage={mainBackgroundImage}
      >
        <TopBanner heading="SERVICES" />
      </BackgroundSection>

      <PageSection id="physio" title="" bgColor={greys[0]} labelColor="black">
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <PhysioCard />
          </Grid>
          <Grid item xs={12} md={6}>
            <ExerciseCard />
          </Grid>
        </Grid>
        <ServiceTable />
      </PageSection>
      <BookNowButton />
      <Footer />
    </>
  );
};

export default ServicesPage;
